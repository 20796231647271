import React from "react"

export const A11yIcon = () => {
  return (
    <svg
      width="898"
      height="898"
      viewBox="0 0 898 898"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_509_2949)">
        <path
          d="M645.438 392.875V336.75H252.562V392.875H392.875V469.598L284.329 659.581L333.046 687.419L437.214 505.125H460.786L564.954 687.419L613.671 659.581L505.125 469.598V392.875H645.438Z"
          fill="currentColor"
        />
        <path
          d="M449 308.688C479.997 308.688 505.125 283.559 505.125 252.562C505.125 221.566 479.997 196.438 449 196.438C418.003 196.438 392.875 221.566 392.875 252.562C392.875 283.559 418.003 308.688 449 308.688Z"
          fill="currentColor"
        />
        <path
          d="M449 841.875C371.297 841.875 295.338 818.833 230.73 775.664C166.122 732.494 115.767 671.135 86.0308 599.347C56.295 527.558 48.5147 448.564 63.6739 372.354C78.8331 296.144 116.251 226.14 171.195 171.195C226.14 116.251 296.144 78.8331 372.354 63.6739C448.564 48.5147 527.558 56.295 599.347 86.0308C671.135 115.767 732.494 166.122 775.664 230.73C818.833 295.338 841.875 371.297 841.875 449C841.758 553.161 800.328 653.022 726.675 726.675C653.022 800.328 553.161 841.758 449 841.875V841.875ZM449 112.25C382.397 112.25 317.29 132 261.912 169.003C206.533 206.005 163.371 258.598 137.883 320.131C112.396 381.664 105.727 449.373 118.72 514.697C131.714 580.02 163.786 640.023 210.882 687.118C257.977 734.213 317.98 766.286 383.303 779.279C448.626 792.273 516.336 785.604 577.869 760.116C639.402 734.628 691.995 691.466 728.997 636.088C766 580.71 785.75 515.603 785.75 449C785.648 359.72 750.137 274.125 687.006 210.994C623.875 147.863 538.28 112.352 449 112.25V112.25Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_509_2949">
          <rect width="898" height="898" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
