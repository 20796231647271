import React from "react"
import { Box, AtlasButton } from "@fuegokit/react"
import { AfDayIcon, AfNightIcon } from "@fuegokit/fuegoicons-react"

type DemoColormodeToggleProps = {
  activeTheme?: any
  selectedTheme?: any
  setColorMode?: any
  colorMode: any
}

const ColormodeToggle = ({
  activeTheme,
  selectedTheme,
  setColorMode,
  colorMode,
}: DemoColormodeToggleProps) => {
  return (
    <AtlasButton
      activeTheme={selectedTheme}
      ecosystem={selectedTheme.name}
      appearance={`subtle`}
      sx={{
        width: "40px",
        height: "40px",
        opacity: selectedTheme.name === "appfire" ? "0" : "1",
        justifyContent: "center",
        alignItems: "center",
        padding: "revert",
        border: "1px solid",
        borderColor: "border.default",
      }}
      onClick={() => setColorMode(colorMode === "night" ? "day" : "night")}
      aria-label={
        "Switch to " + (colorMode === "night" ? "day" : "night") + " color mode"
      }
    >
      <Box display="flex" color="icon.default">
        {colorMode === "night" ? (
          <AfDayIcon size={"small"} />
        ) : (
          <AfNightIcon size={"small"} />
        )}
      </Box>
    </AtlasButton>
  )
}
export default ColormodeToggle
