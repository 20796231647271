import React from "react"
import {
  StyledDatepickerCalendar,
  StyledDatepickerCalendarGrid,
  StyledDatepickerButton,
} from "./StyledDatepicker"

export const DatepickerCalendar = ({ activeTheme, ...props }: any) => {
  return <StyledDatepickerCalendar $theme={activeTheme} {...props} />
}
export const DatepickerCalendarGrid = ({ activeTheme, ...props }: any) => {
  return <StyledDatepickerCalendarGrid {...props} />
}
export const DatepickerButton = ({ activeTheme, ...props }: any) => {
  return <StyledDatepickerButton {...props} />
}
