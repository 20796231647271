import { useStaticQuery, graphql } from "gatsby"

interface SiteMetadata {
  site: {
    siteMetadata: {
      title: string
      siteName: string
      description: string
      imageUrl: string
      isSearchEnabled: boolean
      isSurveyEnabled: boolean
    }
  }
}

function useSiteMetadata(): any {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          siteName
          description
          imageUrl
          isSearchEnabled
          isSurveyEnabled
        }
      }
    }
  `)
  return data.site.siteMetadata
}

export default useSiteMetadata
