import React, { useEffect, useRef } from "react"
import * as d3 from "d3"

import styled from "styled-components"

import { themeGet } from "@fuegokit/react"
import { GraphItem } from "./types"

const Stats = ({}) => {
  const data: GraphItem[] = [
    { x: "May'23", y: 15 },
    { x: "Aug'23", y: 26 },
    { x: "Nov'23", y: 40 },
    { x: "Dec'23", y: 55 },
  ]
  const d3Container = useRef<SVGSVGElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const drawGraph = (width: number) => {
    // Clear existing SVG content
    d3.select(d3Container.current).selectAll("*").remove()

    // Set dimensions based on the width
    const margin = { top: 24, right: 30, bottom: 30, left: 0 }
    const adjustedWidth = width - margin.left - margin.right
    const height = 320 - margin.top - margin.bottom

    // Create SVG
    const svg = d3
      .select(d3Container.current)
      .attr("width", width)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    // Set up scales
    const x = d3
      .scalePoint()
      .domain(data.map(d => d.x))
      .range([0, adjustedWidth])
      .padding(0.2)

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d: GraphItem) => d.y)])
      .range([height, 0])

    // Create the line
    const line = d3
      .line()
      .x((d: GraphItem) => x(d.x))
      .y((d: GraphItem) => y(d.y))
      .curve(d3.curveMonotoneX)

    // Draw the line
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#1D7AFC")
      .attr("stroke-width", 1.5)
      .attr("d", line)

    // Add the X Axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .style("font-size", "12px")
      .call(d3.axisBottom(x))

    // Add the Y Axis
    svg.append("g").call(d3.axisLeft(y))

    const tooltip = d3.select(".graph-tooltip")

    // Append circles for each data point
    svg
      .selectAll(".dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "graph-dot")
      .attr("cx", (d: GraphItem) => x(d.x))
      .attr("cy", (d: GraphItem) => y(d.y))
      .attr("r", 6) // Radius of the dots

      .on(
        "mouseover",
        (event: { pageX: string; pageY: number }, d: GraphItem) => {
          tooltip.transition().duration(100).style("opacity", 1)
          tooltip
            .html(d.y + " apps")
            .style("left", event.pageX + "px")
            .style("top", event.pageY - 28 + "px")
        }
      )
      .on("mouseout", (d: GraphItem) => {
        tooltip.transition().duration(100).style("opacity", 0)
      })
  }

  const handleResize = () => {
    if (containerRef.current) {
      drawGraph(containerRef.current.clientWidth)
    }
  }

  useEffect(() => {
    // Ensure containerRef.current is not null before observing
    if (containerRef.current) {
      drawGraph(containerRef.current.clientWidth)

      const resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(containerRef.current)

      return () => resizeObserver.disconnect()
    }
  }, [data])

  return (
    <GraphContainer ref={containerRef}>
      <svg
        className="d3-component"
        style={{ width: "100%", height: "auto" }}
        ref={d3Container}
      />
      <GraphTooltip className="graph-tooltip" />
    </GraphContainer>
  )
}

const GraphContainer = styled.figure`
  margin: 0;

  .graph-dot {
    fill: ${themeGet("colors.elevation.surface.default.[default]")};
    stroke: ${themeGet("colors.chart.brand.default")};
    stroke-width: 2px;
  }

  .tick {
    color: ${themeGet("colors.text.subtlest")};
  }
  .tick line {
    opacity: 0;
  }

  .domain {
    stroke: ${themeGet("colors.border.default")};
  }
`

const GraphTooltip = styled.div`
  opacity: 0;
  position: absolute;
  width: auto;
  padding: 8px;
  font: ${themeGet("fontSizes.3")} sans-serif;
  background: ${themeGet("colors.background.neutral.bold.default")};
  color: ${themeGet("colors.text.inverse")};
  border: 0px;
  border-radius: ${themeGet("radii.1")};
  padding: ${themeGet("space.2")} ${themeGet("space.3")};
  pointer-events: none;
  line-height: ${themeGet("lineHeights.relaxed")};
`
export default Stats
