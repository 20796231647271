import React from "react"
import styled from "styled-components"
import { themeGet, sx, SxProp, Box, BoxProps } from "@fuegokit/react"
import { TemporaryDemoType } from "./types"

type DemoModalBoxProps = TemporaryDemoType

const colors: { [key: string]: any } = {
  background: {
    atlassian: {
      default: `colors.elevation.surface.default.[default]`,
    },
    monday: {
      default: `colors.elevation.surface.default.[default]`,
    },
    formula: {
      default: `colors.elevation.surface.default.[default]`,
    },
    appfire: {
      default: `colors.elevation.surface.raised.default`,
    },
  },
  boxShadowColor: {
    atlassian: {
      // @TODO -- if needed
    },
    monday: {
      // @TODO -- if needed
    },
    formula: {
      // @TODO -- if needed
    },
    appfire: {
      // @TODO -- if needed
    },
  },
  borderColor: {
    atlassian: {
      // @TODO -- if needed
    },
    monday: {
      // @TODO -- if needed
    },
    formula: {
      // @TODO -- if needed
    },
    appfire: {
      // @TODO -- if needed
    },
  },
  color: {
    atlassian: {
      default: `colors.text.default`,
    },
    monday: {
      default: `colors.text.default`,
    },
    formula: {
      default: `colors.text.default`,
    },
    appfire: {
      default: `colors.text.default`,
    },
  },
}

export const DemoModalBox = ({
  ecosystem = "atlassian",
  activeTheme,
  children,
  ...props
}: DemoModalBoxProps & BoxProps) => {
  return (
    <StyledDemoModalBox ecosystem={ecosystem} {...props}>
      {children}
    </StyledDemoModalBox>
  )
}

const StyledDemoModalBox = styled(Box)<BoxProps & DemoModalBoxProps & SxProp>`
  padding: ${themeGet("space.4")};
  border-radius: ${themeGet("radii.1")};
  box-shadow: 0px 4px 6px 0px hsla(218, 76%, 15%, 0.05);
  border: 1px solid ${themeGet("colors.border.default")};
  display: flex;
  flex-direction: column;
  gap: ${themeGet("space.4")};
  width: 100%;

  // ecosystem appearances
  background-color: ${p =>
    p.ecosystem ? themeGet(colors.background[p.ecosystem].default) : ""};
  color: ${p =>
    p.ecosystem ? themeGet(colors.color[p.ecosystem].default) : ""};
  border-color: ${p =>
    p.ecosystem ? themeGet(colors.borderColor[p.ecosystem].default) : ""};
  ${sx}
`
export const DemoModalBoxFooter = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${themeGet("space.2")};
  justify-content: flex-end;
  margin-top: ${themeGet("space.3")};
`
