import * as React from "react"
import styled from "styled-components"
import { TestimonialProps } from "./types"

import { themeGet } from "@fuegokit/react"

const Testimonial = ({ by, children }: TestimonialProps) => {
  return (
    <QuoteContent>
      <Quote>{children}</Quote>
      <figcaption>
        <Author>{by}</Author>
      </figcaption>
    </QuoteContent>
  )
}

const QuoteContent = styled.figure`
  margin: 0;
  background: ${themeGet("colors.background.accent.gray.subtlest.default")};
  padding: ${themeGet("space.3")} ${themeGet("space.4")};
  border-radius: 8px;
  font-size: ${themeGet("fontSizes.4")};
  line-height: ${themeGet("lineHeights.default")};
`

const Quote = styled.blockquote`
  &::before {
    content: "“";
  }
  &::after {
    content: "”";
  }
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
    helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
`
const Author = styled.cite`
  display: block;
  text-align: right;
  margin-top: ${themeGet("space.2")};
  font-size: ${themeGet("fontSizes.3")};

  &::before {
    content: "— ";
  }
`

export default Testimonial
