import React, { useState, useEffect } from "react"

import styled, { css } from "styled-components"
import {
  Tabs,
  Box,
  BoxProps,
  Link,
  themeGet,
  useTheme,
  Stack,
  Text,
  VisuallyHidden,
  ThemeProvider,
  Datepicker,
} from "@fuegokit/react"

import {
  AkCheckCircleIcon,
  AkEditorCloseIcon,
  AkChevronLeftIcon,
  AkChevronRightIcon,
} from "@fuegokit/fuegoicons-react"

import ThemeSelector from "../ThemeSelector"
import {
  useProductTheme,
  setToLocalStorage,
} from "../ThemeSelector/ThemeSelector.helpers"

import ColormodeToggle from "../ColormodeToggle"
import Button from "../Button"
import Card from "../Card"
import { TabsList, TabsTrigger } from "../Tabs"
import { Switch, SwitchLabel, SwitchThumb } from "../Switch"
import TextField from "../TextField"
import {
  DatepickerCalendar,
  DatepickerButton,
  DatepickerCalendarGrid,
} from "../Datepicker"

import {
  DemoMessage,
  DemoModalBox,
  DemoModalBoxFooter,
} from "../PlaygroundComponents"

import { theme } from "../ThemeSelector"

import { TemporaryDemoType } from "../PlaygroundComponents/types"

type DemoProps = TemporaryDemoType
type PlaygroundDemoProps = TemporaryDemoType

const HeroPlayground = () => {
  setToLocalStorage("all-themes", theme)

  const { activeTheme, themeLoaded } = useProductTheme()

  const [selectedTheme, setSelectedTheme] = React.useState(
    activeTheme ? activeTheme : theme.atlassian
  )
  const [newTheme] = React.useState()

  React.useEffect(() => {
    setSelectedTheme(selectedTheme)
  }, [themeLoaded])

  return (
    <ThemeProvider theme={selectedTheme}>
      <OuterWrapper activeTheme={selectedTheme}>
        <PlaygroundDemo activeTheme={selectedTheme}>
          <Demo
            activeTheme={activeTheme}
            selectedTheme={selectedTheme}
            newTheme={newTheme}
            themeSetter={setSelectedTheme}
          />
        </PlaygroundDemo>
      </OuterWrapper>
    </ThemeProvider>
  )
}

const PlaygroundDemo = ({ activeTheme, children }: PlaygroundDemoProps) => {
  return (
    <StyledPlaygroundDemoWrapper activeTheme={activeTheme}>
      {children}
    </StyledPlaygroundDemoWrapper>
  )
}
const OuterWrapper = styled(Box)<BoxProps & TemporaryDemoType>`
  border-radius: 24px;
  background-color: ${p =>
    p.activeTheme.name === "appfire"
      ? // @TODO: brand tokens don't have nested surface interaction modifiers, but they should
        // right now, if you tried to reference themeGet('colors.elevation.surface.default'), TS will complain that that
        // token doesn't exist in FuegokitThemeTypes; we should keep that as the DX and just add the nested modifier to the token
        "#090a0f"
      : themeGet("colors.elevation.surface.default.[default]")};
`
const StyledPlaygroundDemoWrapper = styled(Box)<BoxProps & TemporaryDemoType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-left: 40px;
  padding-right: 40px;
  padding-top: 48px;
  padding-bottom: 56px;

  /* background-color: ${p =>
    p.activeTheme.name === "appfire"
      ? themeGet("colors.elevation.surface.default.[default]")
      : themeGet("colors.background.neutral.default.[default]")}; */
  color: ${themeGet("colors.text.default")};
  height: 100%;
`
const StyledDemoWrapper = styled(Box)<BoxProps & TemporaryDemoType>`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  ${p =>
    p.selectedTheme.name === "appfire" &&
    css`
      text-rendering: geometricPrecision;
      font-family: "Area Normal Regular";
    `}
  font-family: ${themeGet("fontFamilies.normal")};

  @media (min-width: ${themeGet("breakpoints.3")}) {
    display: flex;
    flex-direction: row;
    max-width: 960px;
  }
`

const DatepickerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledDatepickerCalendar = styled(DatepickerCalendar)`
  > div:first-of-type {
    display: none;
  }
`

// @TODO - types
const Demo = ({ activeTheme, selectedTheme, themeSetter }: DemoProps) => {
  const { colorMode, setColorMode } = useTheme()

  const [currentDate, setCurrentDate] = useState(new Date())
  useEffect(() => {
    // Update the state to the current date when the component mounts
    setCurrentDate(new Date())
  }, [])
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  // Get the current month name and year
  const monthName = monthNames[currentDate.getMonth()]
  // Get the current month and year
  const year = currentDate.getFullYear()

  return (
    <ThemeProvider theme={selectedTheme}>
      <StyledDemoWrapper selectedTheme={selectedTheme}>
        <Stack size={3} sx={{ alignItems: "start", gap: "2rem", flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              gap: "2rem",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "3rem",
            }}
          >
            <ThemeSelector
              themeSetter={themeSetter}
              activeTheme={activeTheme}
              selectedTheme={selectedTheme}
            />
            <ColormodeToggle
              colorMode={colorMode}
              selectedTheme={selectedTheme}
              activeTheme={selectedTheme}
              setColorMode={setColorMode}
            />
          </Box>
          <Box
            sx={{
              height: "13rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/** Modal Dialog */}
            <DemoModalBox ecosystem={selectedTheme.name}>
              <Text
                sx={{
                  fontSize: 4,
                  fontWeight:
                    selectedTheme.name === "appfire" ? "normal" : "semibold",
                }}
              >
                New project
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box
                  as="label"
                  htmlFor={`${selectedTheme.name}-text-field`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    fontSize: selectedTheme.name === "appfire" ? 2 : "revert",
                  }}
                >
                  Project name
                </Box>

                <TextField
                  activeTheme={selectedTheme}
                  id={`${selectedTheme.name}-text-field`}
                  onChange={function noRefCheck() {}}
                  onMouseDown={function noRefCheck() {}}
                />
              </Box>
              <DemoModalBoxFooter>
                <Button
                  activeTheme={selectedTheme}
                  ecosystem={selectedTheme.name}
                  appearance={`subtle`}
                  sx={{
                    backgroundColor: "background.neutral.subtle.default",
                    border: "none",
                    color: "text.default",
                    ":hover": {
                      backgroundColor: "background.neutral.subtle.hovered",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  appearance={`primary`}
                  ecosystem={selectedTheme.name}
                  activeTheme={selectedTheme}
                >
                  Create
                </Button>
              </DemoModalBoxFooter>
            </DemoModalBox>
          </Box>
          <Box as="form" sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {/** Switch */}

            <Switch activeTheme={selectedTheme} id="fun-switch">
              <SwitchThumb activeTheme={selectedTheme}>
                <VisuallyHidden>Just for fun</VisuallyHidden>
              </SwitchThumb>
            </Switch>
            <SwitchLabel activeTheme={selectedTheme} htmlFor="fun-switch">
              Just for fun
            </SwitchLabel>
          </Box>
          {/** Card */}
          <Card activeTheme={selectedTheme} />
        </Stack>

        <Stack
          size={3}
          sx={{
            alignItems: "stretch",
            gap: "2rem",
            flex: 1,
          }}
        >
          {/** Message */}
          <DemoMessage activeTheme={selectedTheme}>
            <Box
              sx={
                selectedTheme.name === "appfire"
                  ? { color: "icon.success.default" }
                  : { color: "icon.success" }
              }
            >
              <AkCheckCircleIcon size={"small"} />
            </Box>
            <Stack size={3}>
              <Text
                sx={{
                  color: "text.default",
                  fontSize: 3,
                  fontFamily:
                    selectedTheme.name === "appfire"
                      ? '"Area Normal Regular"'
                      : "revert",
                  fontWeight:
                    selectedTheme.name === "appfire" ? "normal" : "semibold",
                }}
              >
                Task DS-312 created
              </Text>
              <Box>
                <Link>View task</Link> • <Link>Add to next sprint</Link>
              </Box>
            </Stack>
            <Box
              color="icon.subtle"
              sx={{
                position: "absolute",
                top: 2,
                right: 2,
              }}
            >
              <AkEditorCloseIcon />
            </Box>
          </DemoMessage>
          {/** Datepicker */}
          <Datepicker>
            <StyledDatepickerCalendar activeTheme={selectedTheme}>
              <DatepickerHeader>
                <DatepickerButton slot="previous">
                  <AkChevronLeftIcon />
                </DatepickerButton>
                <Text
                  sx={{
                    color: "text.default",
                    fontSize: 3,
                    fontFamily:
                      selectedTheme.name === "appfire"
                        ? '"Area Normal Regular"'
                        : "revert",
                    fontWeight:
                      selectedTheme.name === "appfire" ? "normal" : "semibold",
                  }}
                >
                  {monthName} {year}
                </Text>

                <DatepickerButton slot="next">
                  {" "}
                  <AkChevronRightIcon />
                </DatepickerButton>
              </DatepickerHeader>
              <DatepickerCalendarGrid>
                {/** @TODO - types */}
                {(date: any) => <Datepicker.CalendarCell date={date} />}
              </DatepickerCalendarGrid>
            </StyledDatepickerCalendar>
          </Datepicker>
          {/** Tabs */}
          <Tabs defaultValue="font-weights">
            <TabsList activeTheme={selectedTheme} aria-label="tabs example">
              <TabsTrigger activeTheme={selectedTheme} value="font-sizes">
                Font sizes
              </TabsTrigger>
              <TabsTrigger activeTheme={selectedTheme} value="font-weights">
                Font weights
              </TabsTrigger>
              <TabsTrigger activeTheme={selectedTheme} value="line-heights">
                Line heights
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </Stack>
      </StyledDemoWrapper>
    </ThemeProvider>
  )
}

export default HeroPlayground
