import React from "react"
import styled from "styled-components"
import { themeGet, Button, sx, Datepicker } from "@fuegokit/react"

export const StyledDatepickerCalendar = styled(Datepicker.Calendar)`
  color: ${themeGet("colors.text.default")};
  background-color: ${themeGet("colors.elevation.surface.default.[default]")};
  padding: ${themeGet("space.4")};
  border-radius: ${themeGet("radii.1")};
  border: 1px solid ${themeGet("colors.border.default")};
  box-shadow: 0px 4px 6px 0px hsla(218, 76%, 15%, 0.05);

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${themeGet("space.3")};
    font-weight: 400;
    color: ${themeGet("colors.text.default")};
    h2 {
      font-family: ${themeGet("fontFamily.sansSerif")};
      font-size: ${themeGet("fontSizes.2")};
      font-weight: ${themeGet("fontWeights.semibold")};
    }
    svg {
      color: ${themeGet("colors.icon.default")};
    }
  }
`
export const StyledDatepickerCalendarGrid = styled(Datepicker.CalendarGrid)`
  width: 100%;
  text-align: center;
  margin: ${themeGet("space.3")} 0;
  th,
  td div {
    padding: ${themeGet("space.2")} ${themeGet("space.1")};
  }

  th {
    cursor: default;
  }
  td {
    cursor: pointer;

    &:hover {
      background-color: ${themeGet(
        "colors.background.neutral.default.hovered"
      )};
      border-radius: ${themeGet("radii.1")};
    }

    [data-selected] {
      background-color: ${themeGet(
        "colors.background.selected.default.[default]"
      )};
      color: ${themeGet("colors.text.selected")};
    }
  }
`
export const StyledDatepickerButton = styled(Datepicker.Button)`
  color: ${themeGet("colors.icon.default")};
  padding: ${themeGet("space.1")};
  border-radius: ${themeGet("radii.1")};
  cursor: pointer;
  background-color: transparent;
  border: 1px solid;
  border-color: transparent;

  &:hover {
    background-color: ${themeGet("colors.background.neutral.default.hovered")};
    border-color: ${themeGet("colors.background.neutral.default.hovered")};
  }
`
