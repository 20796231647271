import React from "react"

export const AppfireIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.88 18.4815L16.3223 11.3886C16.2624 11.8681 16.1625 12.3477 15.9826 12.7872C15.8227 13.2268 15.6028 13.6464 15.343 14.046L16.782 16.9231L8.60753 13.5065C7.80807 13.1668 7.20847 12.5475 6.88868 11.7283C6.58888 10.9091 6.62886 10.03 7.02859 9.27073L9.9866 3.33666L12.9446 9.25075C13.3244 10.03 13.3843 10.9091 13.0845 11.7083C12.7847 12.5275 12.1851 13.1469 11.3657 13.4865L11.3257 13.5065L13.6242 14.4655C14.2437 13.9061 14.7234 13.2068 15.0232 12.4276C15.5229 11.0889 15.4429 9.59041 14.8034 8.31169L10.906 0.579421C10.7461 0.21978 10.3863 0 9.9866 0C9.58687 0 9.22711 0.21978 9.06722 0.579421L5.18982 8.33167C4.55025 9.61039 4.47031 11.1089 4.96997 12.4476C5.46964 13.7862 6.50894 14.8651 7.82805 15.4246L18.5608 19.9201C18.6808 19.98 18.8207 20 18.9606 20C19.2404 20 19.5202 19.8801 19.7201 19.6803C19.9999 19.3407 20.0598 18.8611 19.88 18.4815Z"
        fill="currentColor"
      />
      <path
        d="M6.20693 15.6643L3.20894 16.9231L4.64798 14.0659C4.38815 13.6663 4.1683 13.2468 4.00841 12.8072C3.84851 12.3477 3.72859 11.8681 3.66863 11.4086L0.111024 18.4815C-0.0888419 18.8611 -0.00889559 19.3407 0.270917 19.6404C0.470782 19.8601 0.750594 19.96 1.03041 19.96C1.17031 19.96 1.31022 19.9401 1.43014 19.8801L8.64529 16.8631L7.40613 16.3437C6.98641 16.1838 6.58668 15.9441 6.20693 15.6643Z"
        fill="currentColor"
      />
    </svg>
  )
}
