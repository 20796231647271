import React from "react"
import styled from "styled-components"
import {
  Text,
  Card as CardPrimitive,
  CardProps as CardPrimitiveProps,
} from "@fuegokit/react"
import Button from "../Button"

const Card = ({ activeTheme, ...props }: any) => {
  return (
    <CardPrimitive
      sx={{
        alignItems: "flex-start",
        border: "1px solid",
        borderColor:
          activeTheme.name === "appfire" ? "rgb(92,91,94)" : "border.default",
        borderRadius: activeTheme.name === "appfire" ? "20px" : "radii.1",
        display: "flex",
        flexDirection: "column",
        padding: activeTheme.name === "appfire" ? "24px" : 3,
      }}
    >
      <CardPrimitive.Content>
        <CardPrimitive.Header>
          <Text
            sx={{
              fontSize: activeTheme.name === "appfire" ? 6 : "revert",
              fontWeight: activeTheme.name === "appfire" ? "300" : "semibold",
              fontFamily:
                activeTheme.name === "appfire"
                  ? '"Area Normal Regular"'
                  : "revert",
            }}
          >
            Global configuration
          </Text>
        </CardPrimitive.Header>
        <CardPrimitive.Body
          sx={{
            padding: "12px 0px",
            fontFamily:
              activeTheme.name === "appfire"
                ? '"Area Normal Regular"'
                : "revert",
          }}
        >
          <Text
            as="p"
            sx={{
              color: "text.subtlest",
              fontSize: 2,
              fontWeight: "normal",
              lineHeight: 1.4,
            }}
          >
            Configure notifications, auto-refresh issues, showing transitions
            activity on the issue view, and more.
          </Text>
        </CardPrimitive.Body>
        <CardPrimitive.Footer
          sx={{
            marginTop: "auto",
            padding: "12px 0 6px 0",
            display: "flex",
            justifyContent:
              activeTheme.name === "appfire" ? "flex-end" : "flex-start",
          }}
        >
          <Button activeTheme={activeTheme}>Go to configuration</Button>
        </CardPrimitive.Footer>
      </CardPrimitive.Content>
    </CardPrimitive>
  )
}
export default Card
