import * as React from "react"
import { Box, sx } from "@fuegokit/react"
import styled from "styled-components"

import { Head, Header } from "@fuegokit/gatsby-theme-fuegodocs"

import useSiteMetadata from "../../hooks/use-site-metadata"
import Footer from "../Footer"

// @TODO - types
const Layout = ({ pageContext, children }: any) => {
  const siteMetadata = useSiteMetadata()

  return (
    <>
      <Head
        title={pageContext.frontmatter.title}
        description={pageContext.frontmatter.description}
      />

      <Box
        as="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          backgroundColor: "background.accent.gray.subtlest",
        }}
      >
        <Box
          as="div"
          sx={{ maxWidth: "1200px", margin: "0 auto", width: "100%" }}
        >
          <Header packageName="website" />

          <Main>{children}</Main>

          <Footer />
        </Box>
      </Box>
    </>
  )
}

const Main = styled.main`
  width: 100%;
  margin: 0 auto;
`

Layout.defaultProps = {
  pageContext: {
    frontmatter: {},
  },
}

export default Layout
