import React from "react"
import styled from "styled-components"
import { themeGet, Box, BoxProps } from "@fuegokit/react"
import { TemporaryDemoType } from "./types"

type DemoMessageProps = TemporaryDemoType

export const DemoMessage = ({
  activeTheme,
  children,
  ...props
}: DemoMessageProps) => {
  return (
    <StyledBox activeTheme={activeTheme} {...props}>
      {children}
    </StyledBox>
  )
}

export const StyledBox = styled(Box)<BoxProps & DemoMessageProps>`
  background-color: ${themeGet("colors.elevation.surface.default.[default]")};
  padding: ${themeGet("space.3")} ${themeGet("space.4")};
  padding-left: ${themeGet("space.3")};
  display: flex;
  width: 100%;
  gap: ${themeGet("space.2")};
  border-radius: ${themeGet("radii.1")};
  box-shadow: 0px 4px 6px 0px hsla(218, 76%, 15%, 0.05);
  position: relative;
  border: 1px solid;
  border-color: ${p =>
    p.activeTheme.name === "appfire"
      ? themeGet("colors.border.subtle")
      : themeGet("colors.border.default")};
`
