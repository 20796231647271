import * as React from "react"
import styled from "styled-components"
import { Box, Heading, Stack, themeGet, useTheme, sx } from "@fuegokit/react"
import { AfSlackIcon } from "@fuegokit/fuegoicons-react"
import { Analytics } from "@vercel/analytics/react"
import { Link } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Link"
import Layout from "../components/Layout"
import HeroPlayground from "../components/HeroPlayground"
import Stats, { StaticStats } from "../components/Stats"
import Testimonial from "../components/Testimonial"

import { AppfireIcon, A11yIcon } from "../components/Icon"

type CardDataType = {
  href: string
  imageSrcDark: string
  imageSrcLight: string
  tag: string
  title: string
  subtitle: string
}

const cardsData: CardDataType[] = [
  {
    href: "/tokens",
    imageSrcDark:
      "https://github.com/user-attachments/assets/9366a3b0-0ba8-413f-9776-ea2f51bf9e98",
    imageSrcLight:
      "https://github.com/user-attachments/assets/713805f6-c136-4287-8f98-c0da5a0bb5ce",
    tag: "Design + Dev",
    title: "Tokens",
    subtitle: "Install, browse, use",
  },
  {
    href: "/react",
    imageSrcDark:
      "https://github.com/user-attachments/assets/3d0a689c-5a49-42f4-9c07-9dddb07247c3",
    imageSrcLight:
      "https://github.com/user-attachments/assets/d03fc10d-89ea-4002-948c-f9299841d659",
    tag: "Dev",
    title: "Low-level React components",
    subtitle: "Learn how to install and use",
  },
  {
    href: "/atlassian-react-components",
    imageSrcDark:
      "https://github.com/user-attachments/assets/beb64bd0-4e5e-4641-afa0-08bc768249ac",
    imageSrcLight:
      "https://github.com/user-attachments/assets/609750ef-c951-4247-9762-2374aa01968d",
    tag: "Dev",
    title: "Fully styled Atlassian React components",
    subtitle: "Import and use",
  },
  {
    href: "/design-atlassian",
    imageSrcDark:
      "https://github.com/user-attachments/assets/493209a0-873b-47fc-8e49-64aede0b883e",
    imageSrcLight:
      "https://github.com/user-attachments/assets/10fa7a67-01c5-4071-827b-6ef5c9b32c74",
    tag: "Design",
    title: "Atlassian components",
    subtitle: "Get started in Figma",
  },
  {
    href: "/design-monday",
    imageSrcDark:
      "https://github.com/user-attachments/assets/2408bdae-befc-49f0-b13d-88461aefd991",
    imageSrcLight:
      "https://github.com/user-attachments/assets/0a737d33-7a1d-4b56-9d45-1aa0efb19d65",
    tag: "Design",
    title: "monday.com components",
    subtitle: "Get started in Figma",
  },
  {
    href: "/fuegoicons",
    imageSrcDark:
      "https://github.com/user-attachments/assets/6892b9f9-d0a4-486a-8862-d07353780725",
    imageSrcLight:
      "https://github.com/user-attachments/assets/52fab15c-6eed-457c-9e49-4f8eb16632de",
    tag: "Dev",
    title: "Fuegoicons",
    subtitle: "Crafted for Appfire products",
  },
]

const IndexPage = () => {
  const { colorMode, setColorMode } = useTheme()

  return (
    <Layout>
      <GetStarted>
        <StyledContainer>
          <Stack size={3} sx={{ mx: 3, mt: 3 }}>
            <MainHeading as="h1">Build once, ship everywhere</MainHeading>
            <StyledSubheading as="h2" variant="h400">
              Design, build, and extend Appfire products with Fuegokit.
            </StyledSubheading>
          </Stack>

          <Stack size={2}>
            <GetStartedList>
              {cardsData.map(cardData => (
                <GetStartedCard
                  key={cardData.href}
                  target="_blank"
                  href={cardData.href}
                >
                  <ImageWrapper
                    src={
                      colorMode === "night"
                        ? cardData.imageSrcDark
                        : cardData.imageSrcLight
                    }
                    alt=""
                  />

                  <FloatingTag>{cardData.tag}</FloatingTag>
                  <CardTitle as="h3">{cardData.title}</CardTitle>
                  <CardSubtitle>{cardData.subtitle}</CardSubtitle>
                </GetStartedCard>
              ))}
            </GetStartedList>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
              }}
            ></Box>
          </Stack>
        </StyledContainer>

        <Box>
          <Heading
            sx={{
              marginTop: 4,
              marginBottom: 6,
              textAlign: "center",
              font: "font.heading.xlarge",
            }}
          >
            See it in action ↓
          </Heading>
          <HeroPlayground />
        </Box>

        <Box>
          <Heading
            sx={{
              marginTop: 4,
              marginBottom: 6,
              textAlign: "center",
              font: "font.heading.xlarge",
            }}
            id="stats"
          >
            You're in good company
          </Heading>
          <StyledContainer sx={{ marginBottom: 4 }}>
            <SecondaryHeading as="h3">
              Fuegokit is in use in over 50 Appfire products
            </SecondaryHeading>
            {/* <Stats /> */}
            <StaticStats />
          </StyledContainer>
          <StyledContainer sx={{ marginBottom: 4 }}>
            <SecondaryHeading as="h3">
              Built with feedback from the community
            </SecondaryHeading>
            <TestimonialList>
              <Testimonial by="Ross">
                ZERO developer time was required on our part. ZERO need for QA,
                support tickets, meetings, or a tortured handoff process. Thank
                you for providing such an awesome, streamlined process that
                makes my workday so much better!
              </Testimonial>
              <Testimonial by="Masha">
                The autocomplete for Fuegokit tokens is such a bliss ✨
              </Testimonial>
              <Testimonial by="Leo">
                The fuegokit repository has a very well defined structure and it
                made our lives easier to replicate the structure when creating
                the timeline module. It was easy to understand where things were
                configured and we could quickly setup the module with the
                component structure, build configurations and storybook/docs
                configurations. Yarn workspace worked really well and made
                building all project modules much easier.
              </Testimonial>
              <Testimonial by="(also) Ross">
                [Fuegokit React] was not difficult at all. Someone did the heavy
                lifting for me… I just had to hook it up 🙂 Seriously though,
                Tokens are so easy to use, it just works. Very awesome work.
              </Testimonial>
              <Testimonial by="Sunita">
                Fuegokit is undoubtedly a great addition to Appfire!
              </Testimonial>
            </TestimonialList>
          </StyledContainer>
        </Box>

        <Box>
          <Heading
            sx={{
              marginTop: 4,
              marginBottom: 6,
              textAlign: "center",
              font: "font.heading.xlarge",
            }}
          >
            There's more...
          </Heading>
          <StyledContainer sx={{ marginBottom: 4 }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
                gap: 4,
              }}
            >
              <PlayfulStackCard href="#">
                <AfSlackIcon />
                <CardTitle as="h3">Join #design-systems on Slack</CardTitle>
                <CardSubtitle>
                  Stay up to date with our weekly check-ins
                </CardSubtitle>
              </PlayfulStackCard>

              <PlayfulStackCard href="/accessibility/overview">
                <A11yIcon />
                <CardTitle as="h3">Accessibility</CardTitle>
                <CardSubtitle>Build high quality software</CardSubtitle>
              </PlayfulStackCard>

              <PlayfulStackCard href="/about">
                <AppfireIcon />
                <CardTitle as="h3">About</CardTitle>
                <CardSubtitle>Our focus and guiding principles</CardSubtitle>
              </PlayfulStackCard>
            </Box>
          </StyledContainer>
        </Box>
      </GetStarted>

      <Analytics mode={"production"} />
    </Layout>
  )
}

const GetStartedList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  @media (min-width: ${themeGet("breakpoints.2")}) {
    grid-template-columns: repeat(3, 1fr);
  }
  gap: ${themeGet("space.1")};
  padding: 0;
`

const PlayfulStackCard = styled(Link)`
  border: 1px solid ${themeGet("colors.border.default")};
  padding: ${themeGet("space.6")} ${themeGet("space.4")};
  border-radius: ${themeGet("radii.3")};
  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${themeGet("colors.text.default")};
  gap: ${themeGet("space.1")};
  font-weight: ${themeGet("fontWeights.semibold")};

  transition: transform 300ms;
  min-height: 200px;

  &:hover,
  &:focus {
    transition: transform 150ms;
    transform: translateY(-4px);
  }

  svg {
    width: 24px;
    height: auto;
    opacity: 0.5;
    margin-bottom: ${themeGet("space.3")};
  }

  &:hover svg {
    opacity: 1;
  }

  &:visited {
    color: ${themeGet("colors.text.default")};
  }
`

const ImageWrapper = styled.img`
  width: 100%;
  padding-bottom: ${themeGet("space.2")};
  filter: grayscale(1);
`

const FloatingTag = styled.span`
  position: absolute;
  top: 12px;
  right: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${themeGet("colors.background.accent.blue.bolder")};
  color: ${themeGet("colors.text.inverse")};
  transition: transform 400ms;
  font-size: ${themeGet("fontSizes.1")};
  text-transform: uppercase;
`

const GetStartedCard = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${themeGet("space.3")} ${themeGet("space.3")};
  border-radius: ${themeGet("radii.1")};

  color: ${themeGet("colors.text.default")};

  &:hover,
  &:focus {
    background-color: ${themeGet("colors.background.neutral.subtle.hovered")};

    ${ImageWrapper} {
      filter: grayscale(0);
    }
  }
  &:visited {
    color: ${themeGet("colors.text.default")};
  }

  &:hover {
    ${FloatingTag} {
      transition: transform 200ms;
      transform: rotate(-8deg);
    }
  }
`

const CardTitle = styled(Heading)`
  font-weight: ${themeGet("fontWeights.semibold")};
  padding: ${themeGet("space.1")} 0;
  font-size: ${themeGet("fontSizes.3")};
  margin: ${themeGet("space.1")} 0;
`
const CardSubtitle = styled.span`
  font: ${themeGet("font.body.small")};
  color: ${themeGet("colors.text.subtle")};
`

const MainHeading = styled(Heading)`
  font-size: ${themeGet("fontSizes.7")};
`

const SecondaryHeading = styled(Heading)`
  font: ${themeGet("font.heading.medium")};
`

const StyledSubheading = styled(Heading)`
  font-size: ${themeGet("fontSizes.4")};
  font-weight: ${themeGet("fontWeights.medium")};
  color: ${themeGet("colors.text.subtle")};
`

const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${themeGet("space.5")};
  padding: ${themeGet("space.6")};
  border-radius: 24px;
  background-color: ${themeGet("colors.elevation.surface.default.[default]")};

  a {
    text-decoration: none;
  }

  ${sx}
`

const TestimonialList = styled.div`
  column-width: 312px;
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  column-gap: ${themeGet("space.3")};

  figure {
    break-inside: avoid;
    margin-bottom: ${themeGet("space.3")};
  }
`
const GetStarted = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${themeGet("space.6")};
  margin: ${themeGet("space.6")} 0;

  @media (min-width: ${themeGet("breakpoints.1")}) {
    margin: ${themeGet("space.6")} 0;
    gap: ${themeGet("space.10")};
  }
  @media (min-width: ${themeGet("breakpoints.2")}) {
    margin: ${themeGet("space.8")} 0;
  }
`

export default IndexPage
